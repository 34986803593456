


















import { namespace } from "vuex-class";

import { Component } from "vue-property-decorator";
import NotFound from "@/views/NotFound.vue";
import CustomerDossier from "@/views/dossier/CustomerDossier.vue";
import EmployeeDossier from "@/views/dossier/EmployeeDossier.vue";
import Page from "@/components/layouts/Page.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import TheHeader from "@/components/TheHeader.vue";

const user = namespace("user");

@Translatable("dossier")
@Component({
  components: { EmployeeDossier, CustomerDossier, NotFound, Page, TheHeader },
})
export default class Dossier extends TranslatableComponent {
  @user.Getter("isCustomer")
  private isCustomer!: boolean;

  @user.Getter("isEmployee")
  private isEmployee!: boolean;
}
