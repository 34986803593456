



























































import { Component, Watch } from "vue-property-decorator";
import Loader from "@/components/Loader.vue";
import SearchBar from "@/components/dossier/SearchBar.vue";
import { Account } from "@/interfaces/Account";
import DjlMultiselect from "@/components/form-components/djlMultiselect.vue";

import DocumentList from "@/components/dossier/DocumentList.vue";
import mFiles, { DossierDocument } from "@/api/mFiles";
import { AxiosResponse } from "axios";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

import { TranslateResult } from "vue-i18n";
import accessControl from "@/api/access_control";
import { resolvePromiseSettledResult } from "@/utils/promises";

@Component({
  components: {
    DocumentList,
    Loader,
    SearchBar,
    DjlMultiselect,
  },
})
@Translatable("dossier")
export default class CustomerDossier extends TranslatableComponent {
  private isLoadingAccounts: boolean = true;
  private isLoadingDossierDocuments: boolean = false;
  private documents: DossierDocument[] = [];
  private accounts: Account[] = [];
  private account: Account | null = null;
  private filter: string = "";
  private filters: ((o: DossierDocument) => boolean)[] = [];
  private isShowMFilesDmsFileUpload: boolean = false;

  onSearchBarChange(value: string) {
    this.filter = value;
  }

  get hasDocuments(): boolean {
    return this.documents.length > 0;
  }

  get hasAccounts(): boolean {
    return this.accounts.length > 0;
  }

  mounted() {
    this.isShowMFilesDmsFileUpload =
      process.env.VUE_APP_SHOW_MFILES_DMS_FILE_UPLOAD?.toLowerCase() === "true";

    accessControl.accounts
      .accountsWithRole("dossier")
      .then((response: AxiosResponse<Account[]>) => {
        this.accounts = response.data;
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_api_value("access_control.error.fetch_accounts")
        );
        this.accounts = [];
      })
      .finally(() => {
        this.isLoadingAccounts = false;
        this.renderDossierDocuments(this.accounts);
      });
  }

  async renderDossierDocuments(accounts: Account[]) {
    this.documents = [];

    if (accounts.length === 0) return;

    this.isLoadingDossierDocuments = true;

    const dossierDocumentsPromises: Promise<DossierDocument[]>[] = [];

    accounts.forEach((account: Account) => {
      dossierDocumentsPromises.push(this.getDossierDocuments(account));
    });

    this.documents = await Promise.allSettled(dossierDocumentsPromises)
      .then(resolvePromiseSettledResult)
      .catch(() => []);

    this.isLoadingDossierDocuments = false;
  }

  getDossierDocuments(selectedAccount: Account): Promise<DossierDocument[]> {
    return mFiles.getDocumentsWithAccount(selectedAccount).catch(() => {
      this.$snotify.error(
        this.translated_view_value("can_not_get_documents", {
          name: selectedAccount.name,
        })
      );

      return [];
    });
  }

  @Watch("account")
  accountChanged(account: Account) {
    this.filters = [];

    if (account) {
      this.filters.push(
        (o: DossierDocument): boolean => o.account.id === account?.id
      );
    }
  }

  get hasSelectedAccount(): boolean {
    return this.filters.length > 0;
  }

  get hasActiveFilter(): boolean {
    return this.filter.length > 0;
  }

  get noResultsMessage(): TranslateResult | string {
    if (!this.hasDocuments && this.hasAccounts) {
      return this.translated_view_value("no_documents_found_message");
    }

    if (this.hasDocuments && this.hasAccounts) {
      if (this.hasSelectedAccount) {
        return this.translated_view_value("selected_entity_has_no_documents");
      }

      if (this.hasActiveFilter) {
        return this.translated_view_value("no_search_results");
      }
    }
    return "";
  }
}
